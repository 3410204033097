<template>

    <!-- subTable container-->
    <div class="tableEntryXLCollapseWrap">
      	
        <!-- subTable header-->
        <div class="tableTitle" v-bind:style="{'grid-template-columns': gridColumnWidths}">

            <component :is="column.component" :header="true" :column="column" :isOverviewTable="isOverviewTable"  class="" v-for="column in columns" />

		</div>  

        <!-- table rows-->
        <component :is="tableRow" v-for="item in items" :item="item" :columns="columns" :gridColumnWidths="gridColumnWidths" ></component>
        
        <div v-if="items.length==0" class="tableEntry" style="text-align: center;">
            <div><span class="tableEntryText">Nog geen strips gebruikt</span></div>
        </div>
    </div>


</template>

<script>
import AppTable from '../../../components/base/Table/Table';
import TableRow from './ActivityTableRow.vue';

export default {
    props: {

    },
    extends: AppTable,
    components:{TableRow},
    methods: {
        
    }

}
</script>