<template>
<div class="tableEntryXLWrap" v-if="!item._hidden">
    <div class="tableEntryXL" v-bind:style="{'grid-template-columns': gridColumnWidths}">
        <template v-for="column in columns">
            <component :is="column.component" :item="item" :column="column" />
        </template>
        

    </div>
</div>
</template>

<script>

//import AppTableCell from '../../../components/base/Table/TableCell';
//import AppTableCellSwitch from '../../../components/base/Table/TableCellSwitch';

export default {
    //components:{AppTableCell, AppTableCellSwitch},
    emits:['showActionBar'],
    data() {
        colI: 0;
    },
    computed: {
        
    },
    methods: {


    },
    props: {
        gridColumnWidths: {
            type: String,
            required:true
        },
        item: {
            type: Object,
            required: true
        },
        columns: {
            type: Array,
            required: true
        }
    },
    mounted() {

    }
}
</script>