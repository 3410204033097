<template>

    <div class="panelSubtitleWrap">
        <h3 class="panelSubtitle">Koppel een bestand</h3>
        <span class="panelSelectedFile"><strong>Selectie: </strong><br />{{selectedFileName}}</span><br /><br /><br />
        <ul class="panelCrumbPath">
            <!-- <li><a href="#">Bibliotheek</a></li>
            <li><a href="#">Bestanden</a></li>
            <li>Cursusmateriaal</li> -->
            <li v-for="pathItem in activePath">
                <a v-if="pathItem.click && activePath.length>1" @click="activeCategoryId=pathItem.id" class="cursor">{{pathItem.title}}</a>
                <template v-else>{{pathItem.title}}</template>
            </li>
        </ul>
    </div>

    <div class="table">
			
			<div class="tableTitleWrap removeMinWidth">
				<div class="tableTitle" style="grid-template-columns: 7.2rem 5fr 1fr 1fr;">
					<div>
						<span class="tableTitleText">&nbsp;</span>
					</div>
					<div>
						<span class="tableTitleText">Bestandsnaam</span>
					</div>
					<div>
						<span class="tableTitleText">Type</span>
					</div>
					<div class="alignCenter">
						<span class="tableTitleText">Acties</span>
					</div>
				</div>
			</div>
			
			
			
			<div class="tableEntryXLWrap removeMinWidth" v-for="item in visibleItems">
				<div class="tableEntryXL" style="grid-template-columns: 7.2rem 5fr 1fr 1fr">
					<div class="containsLargeIcon alignCenter">
						<div class="tableEntryLinkIcon onOffIcon">
														
							<i class="fa-solid" :class="getItemClass(item)" title="Map"></i>
                        </div>
					</div>
					<div>
						<span class="tableEntryText">
							<a @click="itemClick(item)" class="cursor">{{item.name}}</a>
						</span>
					</div>
					<div>
						<span class="tableEntryText">
							{{getItemType(item)}}
						</span>
					</div>
					<div class="containsLargeIcon alignCenter">
						<a @click="itemIconClick(item)" class="tableEntryLinkIcon cursor">
                            <i class="fa-solid" :class="getItemIconClass(item)"></i>
                        </a>
					</div>
				</div>
			</div>
			
						
		</div>
    
</template>

<script>
import { ref } from 'vue';

export default {
  components: {},
  props: {
      context: {
          type:Object,
          required:true
      },
    //   nodes: {
    //       type:Object,
    //       required:true
    //   }
  },
  data() {
      return {
          selectedFile:false,
          categories:[],
          activeCategoryId:false,
          
      }
  },
  methods: {
    itemClick(item) {
        if (item.FileCategories) {
            this.activeCategoryId = item.id
            return;
        }
        // show selected file
        window.open(process.env.VUE_APP_API_BASE+'/previewFile/'+item.id, "_blank");
    },
    itemIconClick(item) {
        if (item.FileCategories) {
            this.activeCategoryId = item.id
        }
        else {
            // set selected file
            this.selectedFile = item;
            //this.$props.context.value = item.id;
            this.$props.context.node.input(item.id);
        }
    },
    getItemType(item) {
        if (item.FileCategories) { return 'MAP';}
        return item.file_extension.toUpperCase()
    },
    getItemClass(item) {
        if (item.FileCategories) {return 'fa-folder'}

        else return 'fa-file'
    },
    getItemIconClass(item) {
        if (item.FileCategories) {return 'fa-eye'}
        else if (item.id==this.selectedFile.id) return 'fa-check';
        else return 'fa-paperclip'
    }
    //   treeClick(item) {
    //     //return;
    //       console.log('treeClick', item)
    //       if (item.type=='file') {
    //             this.$props.context.node.input(item.fileId);
    //             Object.entries(this.tree.nodes).forEach(node=>{
    //                 if (node[1].type=='file') {
    //                     console.log(node[1])
    //                     if (node[1].fileId==item.fileId) {
    //                         console.log("found", node)
    //                         node[1].state = {
    //                             checked:true
    //                         }
    //                     }
    //                     else {
    //                         node[1].state = {
    //                             checked:false
    //                         }

    //                     }
    //                 }
    //             })
    //       }
    //   },
    //   handleInput(e) {
    //       console.log('handleInput', e)
    //         //this.$props.context.node.input(e.level.content)

    //   },
    //   openFolder(id) {
    //     console.log('openFolder')
    //        var nodeId = 'node_'+id;
    //        if (this.tree.nodes[nodeId]) {
    //             this.tree.nodes[nodeId].state = {
    //                 opened: true
    //             }
    //             if (this.tree.nodes[nodeId].parent_id) {
    //                 this.openFolder(this.tree.nodes[nodeId].parent_id)
    //             }
    //        }
    //   }
  },
  computed: {
    selectedFileName() {
        if (!this.selectedFile) return '-';

        var parentCatId = this.selectedFile.fc_id;
        var path = [];
        //debugger
        while (parentCatId) {
            var cat = this.categories.filter(c=>{return c.id==parentCatId});
            
            if (cat.length) {
                path.push(cat[0].name);
                parentCatId = cat[0].parent_id;
            }

        }
    
        return path.reverse().join('/')+'/'+this.selectedFile.name;
    },
    activePath() {
        var path = [{
            title:'Bibliotheek',
            click:true,
            id:false
        }];
        if (this.activeCategory) {
            var path2 = [];
            var cat = this.activeCategory;
            var stop = false
            while (!stop) {
                var pathItem = {
                    title:cat.name,
                    id:cat.id
                }
                if (cat.id!=this.activeCategoryId) {
                    
                    pathItem.click=true;
                    // pathItem.click = () => {
                    //     console.log()
                    //     this.activeCategoryId= clone(parseInt(cat.id));
                    // }
                }
                path2.push(pathItem);

                if (!cat.parent_id) stop=true;
                else {
                    var cats = this.categories.filter(c=>{return c.id==cat.parent_id})
                    
                    if (!cats.length) stop=true;
                    else {
                        cat=cats[0]
                    }
                
                }
                //if (!cat.parent_id) stop=true;
            }
            path = path.concat(path2.reverse())
        }
        //console.log('activePath', path)
        return path
    },
    activeCategory() {
        if (!this.activeCategoryId) return false;
        var activeCategory = this.categories.filter(c=>{return c.id==this.activeCategoryId});
        if (activeCategory.length) return activeCategory[0];
        return false;
    },
    visibleItems() {
        var items = this.categories.filter(c=>{return c.parent_id==this.activeCategoryId});
        //console.log(items)

        if (this.activeCategory) {
            var files = this.activeCategory.Files
            items = items.concat(files)
        }
        else {

        }
        //console.log('items:', items)
        return items;

    }
    //   height() {
              
    //           if (!this.$props.context.attrs.height) return 400;
    //           return this.$props.context.attrs.height;
          
    //   },
    //   localValue: {
    //       get () {
    //         console.log('get')
    //         if (this.localValue) {
                
    //             return this.localValue
    //         }
    //         return this.$props.context._value;
    //     },
    //     set (value) {
              
    //           console.log("set",value)
    //           //this.localValue = value;
    //           this.$props.context.node.input(value);
    //       },

    //   }
      // propModel: {
      //     get () { return this.$props.context.value },
      //     set (value) { this.$props.context.value.set(value); console.log(value);this.$emit('update:prop', value) },
      // },        
      // val() {
      //     console.log(this.$props)
      //     return this.$props.context.value;
      // }
  },
  watch: {
      // context(newVal) {
      //     this.valueCopy = newVal;
      // }
  },
  
  mounted() {
        console.log('filebrowser mounted', this.$props.context.attrs.folders)
        this.categories = this.$props.context.attrs.folders;
        if (this.$props.context.value) {
            this.categories.forEach(c=>{
                var foundFile = c.Files.filter(f=>{return f.id==this.$props.context.value});
                if (foundFile.length) {
                    this.selectedFile = foundFile[0];
                }
            })
            //var foundFile = this.categories.filter(i=>{return i.id==this.$props.context.value && i.})
        }
        // //debugger;
        // var newNodes = {};
        // var newRoots = [];
        // var newLeaves = [];
        // //this.tree.config.roots = [];
        // //this.tree.config.leaves = [];

        // var openCategoryId = false;
        
        // var nodeMapping = {};


        // this.$props.context.attrs.nodes.forEach(fc => {
        //     const nodeId = 'node_'+fc.id;
        //     if (!fc.parent_id) newRoots.push(nodeId)


        //     const node = {
        //         id: fc.id,
        //         fc_id: fc.id,
        //         text: fc.name,
        //         files: fc.Files
        //     };
        //     if (fc.FileCategories.length) {
        //         node.children = [];
        //         fc.FileCategories.forEach(child => {
        //             const childId = 'node_'+child.id;
        //             node.children.push(childId);
        //         });
        //     }
        //     newNodes[nodeId] = node;
        // });

        // this.$props.context.attrs.nodes.forEach(fc=>{
        //     //this.tree.config.roots.push('node_'+node.id)
        //     var nodeId = 'node_'+fc.id+"_"+new Date().valueOf();

        //     nodeMapping[fc.id] = nodeId;
        //     //console.log(fc)
        //     if (!fc.parent_id) newRoots.push(nodeId)
            


        //     const node = {
        //         type:'folder',
        //         id: fc.id,
        //         fc_id: fc.id,
        //         text: fc.name,
        //         parent_id: fc.parent_id,
        //         //files: fc.Files,
        //         children: []
        //     };

        //     // fc.Files.forEach(file=>{
        //     //     var fileNodeId = 'file_'+file.id+"_"+new Date().valueOf();
        //     //     const fileNode = {
        //     //         type:'file',
        //     //         id: file.id,
        //     //         fileId: file.id,
        //     //         text: file.name,
        //     //     };
        //     //     if (file.id==this.$props.context._value) {
        //     //         openCategoryId = node.id;
        //     //         node.state = {
        //     //             opened: true,
        //     //         }
        //     //         fileNode.state = {
        //     //             checked: true
        //     //         }
        //     //     }
        //     //     newLeaves.push(fileNodeId)
        //     //     newNodes[fileNodeId] = fileNode;
        //     //     node.children.push(fileNodeId);
        //     // })

        //     this.$props.context.attrs.nodes.filter(fc2=>{return fc2.parent_id==fc.id;}).forEach(fc2=>{
                
        //             //node.children.push('node_'+fc2.id)
        //             node.children.push(nodeMapping[fc2.id])
                
        //     })

        //     newNodes[nodeId] = node;

        // })
        //const nodeId = 'node_1';
        //console.log('openCategoryId', openCategoryId)
        //this.openFolder(openCategoryId)

        // this.tree.nodes = newNodes;
        // this.tree.config.roots = newRoots;
        // this.tree.config.leaves = newLeaves;

        // const node = {
        //     id: 1,
        //     fc_id: 1,
        //     text: 'base',
        //     files: []
        // };

        // this.tree.nodes[nodeId] = node
  }
}
// const props = defineProps({
//     context: Object,
// })
// console.log(props)
// var testval = ref(props.context.value)
// function handleInput(e) {
//     console.log(props)
//     console.log(e)
//     testval = e.level.content
//     //props.context.node.input(e.target.value)
//     //props.context.node.input(e.level.content)
//     //props.context.node.input('234')
// }

</script>


<style scoped>
.formkit-label {display: none;}
.panelSelectedFile {font-size: 1.4em;}
</style>