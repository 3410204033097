<template>
<div>
    <span v-if="header" class="tableTitleText">
        {{getEntityPropertyValue()}}
    </span>
    <span v-if="!header" class="tableEntryText" :class="isMarked()?'marked':''" v-html="getDate()">
        
    </span>
</div>
</template>

<script>
import AbstractTableCell from './AbstractTableCell.vue';
export default {
    extends: AbstractTableCell,
    
    methods: {
        getDate() {
            var e = this.getEntityPropertyValue().substring(0,10)  .split('-');
            if (e.length == 3)  return e[2]+'-'+e[1]+'-'+e[0];
            return '-'           
        }   
    }
}
</script>