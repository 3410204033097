<template>

<section class="mainContent">
<!-- {{ pageItems }} -->
    <!-- table container-->
    <div :class="{overview:isOverviewTable, table:!isOverviewTable}">

        <!-- table header-->
        <div :class="{overviewTitleWrap:isOverviewTable, tableTitleWrap:!isOverviewTable}">
		
			<div :class="{overviewTitle:isOverviewTable, tableTitle:!isOverviewTable}" v-bind:style="{'grid-template-columns': gridColumnWidths}">

                <component :is="column.component" :header="true" :column="column" :isOverviewTable="isOverviewTable"  class="" v-for="column in columns" />

			</div>
			
		</div>
        
        

        <!-- table rows-->
        <template v-if="!isLoading">
            <component :is="tableRow" v-for="item in pageItems" :item="item" :columns="columns" :gridColumnWidths="gridColumnWidths" ></component>
        </template>
        <template v-else>
            <div class="tableEntryXLWrap">
                <div class="tableEntryXL">
                    <div><span class="tableEntryText">Laden...</span></div>
                </div>
            </div>
        </template>

        <!-- {{ pagerButtons }} -->

        <template v-if="this.pager">
            <div class="pagerRowContainer">
                <div class="pagerContainer">

                    <span v-for="pagerButton in pagerButtons" class="pagerNumberButton" :class="{active:this.pager.currentPage==pagerButton.text, noAction:pagerButton.type=='none'}" v-html="pagerButton.text" @click="pagerAction(pagerButton)"></span>
                </div>

            </div>

        </template>

            
        
    </div>
</section>




</template>


<script>
import { add } from '@formkit/icons';

export default {
  emits:['showNotes', 'showSidebar'],
  props: {
     //items: [],
    // filter: {
    //     type: Boolean,
    //     required: false
    // },
    items: {
        type: Array,
        required: true
    },
     columns: {
        type: Array,
        required: true
    },
    tableRow: {
        type: [String, Object],
        default: 'div',
    },
    isOverviewTable: {
        type: Boolean,
        required:false
    },
    isLoading: {
        type: Boolean,
        default:false,
        required:false
    },
    pager: {
        type: Object,
        default:false,
        required:false
    }
    // name: {
    //   type: String,
    //   required: true
    // },

    // columns: {
    //   type: Array,
    //   required: true
    // },

    // list: {
    //   type: Array,
    //   required: true
    // }
  },
  computed: {
        pagerButtons() {
            let buttons = [];
            let pages = [];
            if (!this.pager) return buttons;

            let totalPages = Math.ceil(this.items.filter(item=>!item._hidden).length/this.pager.perPage);
            console.log(this.items.length,totalPages)

            if (this.pager.currentPage>1) buttons.push({text:'&laquo;', type:'previous', page:0})
            else buttons.push({text:'', type:'none', page:-0.5})

            pages.push(this.pager.currentPage);
            buttons.push({text:this.pager.currentPage, page:this.pager.currentPage})

            if (this.pager.currentPage<totalPages) buttons.push({text:'&raquo;', type:'next', page:100000000000})

            for (let p=this.pager.currentPage-2; p<=this.pager.currentPage+2; p++) {
                if (p>0 && p<=totalPages && !pages.includes(p) && p>0) {
                    pages.push(p);
                    buttons.push({text:p, page:p})
                }
            }
            

            if (this.pager.currentPage>3 && !pages.includes(1)) {
                pages.push(1);
                buttons.push({text:1, page:1})
                
                if (this.pager.currentPage>5 && !pages.includes(1.5) && totalPages>10) {
                    pages.push(1.5);
                    buttons.push({text:'...', page:1.5, type:'none'})
                }              
                else if (totalPages>=2 && !pages.includes(2)){
                    pages.push(2);
                    buttons.push({text:2, page:2})
                }
            }

            if (this.pager.currentPage<totalPages && !pages.includes(totalPages)) {
                pages.push(totalPages);
                buttons.push({text:totalPages, page:totalPages})
                
                if (this.pager.currentPage<totalPages-4 && !pages.includes(totalPages-0.5) && totalPages>10) {
                    //console.log('-4 add ...')
                    pages.push(totalPages-0.5);
                    buttons.push({text:'...', page:totalPages-0.5, type:'none'})
                }               
                else if (this.currentPage>(totalPages-4) && !pages.includes(totalPages-1) && totalPages>1){
                    pages.push(totalPages-1);
                    buttons.push({text:totalPages-1, page:totalPages-1})
                }              
            }

            let stop = false;
            let addPage = 1;
            if (this.pager.currentPage>=(totalPages-4)) {
                
                addPage = totalPages-1;
            }
            

            let minpages = totalPages>11?11:totalPages;

            while (buttons.length<11 && !stop) {
                if (this.pager.currentPage<=5) {
                    if (!pages.includes(addPage) && addPage>0) {
                        pages.push(addPage);
                        buttons.push({text:addPage, page:addPage})
                        addPage++;
                    }
                    else {
                        if (addPage==totalPages) {
                            
                            stop=true;
                        }
                        addPage++;
                    }
                }
                if (this.pager.currentPage>=(totalPages-4)) {
                    if (!pages.includes(addPage) && addPage>0) {
                        pages.push(addPage);
                        buttons.push({text:addPage, page:addPage})
                        addPage--;
                    }
                    else {
                        if (addPage==totalPages || addPage<=0) {
                            
                            stop=true;
                        }
                        addPage--;
                    }
                }
            }

            // if (this.pager.currentPage<3) {
            //     buttons.push({text:(this.pager.currentPage-2), page:(this.pager.currentPage-2)})
            // }

            return buttons.sort((a,b)=>{return a.page-b.page});

            // for(let p=1;p<=5;p++) {
            //     if (p>totalPages) break;
            //     buttons.push({text:p, page:p})
            // }
            // if (this.pager.currentPage==5) buttons.push({text:this.pager.currentPage, type:'none'}


            // for(let p=5;p<=5;p++) {
            //     if (p>totalPages) break;
            //     buttons.push({text:p, page:p})
            // }

            // buttons.push({text:'&raquo;', type:'next'})

            // if (this.pager.currentPage<3) {
            //     buttons = buttons.slice(1);
            // }
            
            // if (this.pager.currentPage>totalPages-2) {
            //     buttons = buttons.slice(0,buttons.length-1);
            // }

            // if (this.pager.currentPage-2>0) buttons.push({text:(this.pager.currentPage-2), page:(this.pager.currentPage-2)})
            // if (this.pager.currentPage-1>0) buttons.push({text:(this.pager.currentPage-1), page:(this.pager.currentPage-1)})
            
            // buttons.push({text:this.pager.currentPage, type:'none'})

            // if ((this.pager.currentPage+1)<totalPages) buttons.push({text:(this.pager.currentPage+1), page:(this.pager.currentPage+1)})
            // if ((this.pager.currentPage+2)<totalPages) buttons.push({text:(this.pager.currentPage+2), page:(this.pager.currentPage+2)})

            // if (this.pager.currentPage<totalPages) {
            //     buttons.push({text:'&raquo;', type:'next'})
            // }

            return buttons;
        },
        pageItems() {
            if (!this.pager) {
                if (this.items) {
                    return this.items.filter(item=>{return !item._hidden})

                }
                return [];
            }

            return this.items.filter(item=>{return !item._hidden}).slice(
                (this.pager.currentPage-1)*this.pager.perPage,
                this.pager.currentPage*this.pager.perPage
            )
        },
        isLoading() {
            return this.$props.isLoading;
        },
        gridColumnWidths() {
            var widths = [];
            this.$props.columns.forEach(col=>{
                widths.push(col.width)
            })
            return widths.join(' ')
            return '5.6rem 6fr 3fr 5fr 3fr 4fr 4fr 10rem 10rem';
        }
  },
  methods: {
      pagerAction(button) {
        if (button.type=='none') {
            return;
        }
        if (button.type=='next') {
            this.pager.currentPage++;
            return;
        }
        if (button.type=='previous') {
            this.pager.currentPage--;
            return;
        }
        if (button.page) {
            this.pager.currentPage = button.page;
        }
      },
      getTitleHtml(column) { 
          const f = column.component.__file.split('/').at(-1).split('.');
          switch(f[0]) {
              case 'TableCellCheckbox':
                    //return column.component.test();
                    return '<input type="checkbox" />';
                    break;
              case 'TableCellCollapse':
                    return '<input type="checkbox" />';
                    break;
              default:
                    return column.title;
          }
          
      }
  }
}
</script>


<style >
.tableHeader, .tableHeaderCell {
    background-color: aqua;
}
.tableHeaderCell {
        float: left;
    
}

.tableCell, .tableHeaderCell {
    width:25%;
    padding: 5px;
}

.tableBody, .tableRow {
    clear:both;
}

.tableCell {
    float:left;
}

.pagerRowContainer {
    /* text-align: center; */
}
.pagerRowContainer .pagerContainer {
    margin: 2px auto;
    width:500px;
    text-align: center;
}

.pagerRowContainer .pagerContainer span:nth-child(1) {
    /* border-left:1px solid #ccc; */

}
.pagerRowContainer .pagerContainer span {
    font-size: 1.2em;
    width:40px;
    cursor: pointer;
    display:inline-block;
    padding:1em;
    user-select: none;
    text-align: center;
    
    
    /* border-right:1px solid #ccc; */
}
.pagerRowContainer .pagerContainer span.active {
    text-decoration: underline;
    background-color: rgba(49,14,78,.2);
}
.pagerRowContainer .pagerContainer span.noAction {
    
    cursor: auto;
}
</style>