import axios from 'axios'
import { getTransitionRawChildren } from 'vue';
import VueAxios from 'vue-axios'



//import Emittery from 'emittery';



axios.defaults.withCredentials = true

axios.defaults.headers= {
    'Content-Type': 'application/json',
  }

export default {
    //emits:['showActionBar', 'closeSideBar'],
    emits:['close-action-bar', 'closeSideBar', 'close-side-bar', 'show-alert'],
    //emits:['showAlert'],
    data() {
        return {
          //__data: {},
          
          
          _formCopy: [],
          _form: [],
          _formFields: [],
          _entity: {},
          _preItemSave: (entity)=>{
              //console.log('default _preItemSave', entity);
              //entity.pes="klaas"
          },
          _afterItemSave: ()=>{
              //console.log('default _afterItemSave')
          },
          _afterItemSaveSideBar:false
        }
    },
    computed: {

        allFormFields() {
            let fields = []
            this._formFields.forEach(field => {
                if (field.children && field.children.length && Array.isArray(field.children)) {
                    field.children.forEach(childField => { fields.push(childField); });
                }
                else { fields.push(field); }
            });
            return fields;
        },
        openNewTab(url) {
            console.log("openNewTab:", url)
            //window.open(url, "_blank");

        },
        getLogin() {
            return this.$store.getters.get_login
        },
        loggedIn() {
            var login = this.$store.getters.get_login;
            if (login) return true;
            return false;
        },
        routeMainTitle() {
            var mainSection = this.$route.path.split('/')[1];
            var items = this.$router.options.routes.filter(route=>{return route.path == '/'+mainSection});
            if (items[0].sectionTitle) return items[0].sectionTitle;
            
            return '**mainsectionNotFound**';
        },
        subMenuItems() {
            if (this.$route.path=='/') return [];
      
            var mainSection = this.$route.path.split('/')[1];
            
            
            var items = this.$router.options.routes.filter(route=>(!route.title || route.path.startsWith('/'+mainSection+'/')==0?false:true));
            var mainItem = false;
            
            this.$router.options.routes.forEach(route => {
              if (route.path=='/'+mainSection) {
                mainItem = route;
              }
            });
            
            if (mainItem) {
              items = [mainItem, ...items];
      
            }

            // add sectionname to subitems
            items.map(item=>{
                item.sectionName ='blabla'
            })

            var login = this.$store.getters.get_login;
            //console.log('login', login)
            var allowedPages = [];
            if (login && login.pagePermissions) allowedPages = login.pagePermissions;
            
            return items.filter(route=>{
                if (login && login.Role && login.Role.superuser) return true;
                if (!allowedPages.includes(route.name)) return false;
                return true
            });

          }
    },
    components: {
        // 'confirmation-dialog': ConfirmationDialog,
        // 'waiting-dialog': WaitingDialog
        
    },
    methods: {
        getSetting(name) {
            let settings = this.$store.getters.get_settings;
            let found = settings.filter(setting=>{return setting.name==name});
            if (found.length) return found[0].value;
            return false;
        },
        getMailtemplate(name, replacements) {
            let mailtemplates = this.$store.getters.get_mailtemplates;
            let found = mailtemplates.filter(mailtemplate=>{return mailtemplate.name==name});
            if (found.length) {
                var subject = found[0].subject;
                var body = found[0].body;
                for (var key in replacements) {
                    subject = subject.replace(new RegExp('{{'+key+'}}', 'g'), replacements[key]);
                    body = body.replace(new RegExp('{{'+key+'}}', 'g'), replacements[key]);
                }
                return {body:body, subject:subject};
            }
            return false;
        },
        showLoader() {
            //console.log('showLoader')
            this.$store.dispatch('set_loaderstate', true)
        },
        hideLoader() {
            this.$store.dispatch('set_loaderstate', false)
        },
        getAlertCount(routeName) {
            var alertCounts = this.$store.getters.get_alertcounts;
            //console.log('getAlertCount', routeName, alertCounts)
            
            if (alertCounts && alertCounts[routeName]) return alertCounts[routeName];
            return '';
        },

        async uploadFileAndData(url, data, file) {
            try {
                let formData = new FormData();
                formData.append("file", file);

                var jsonEncoded = {};
                
                console.log('uploadFileAndData', data)
                for (var i in data) {
                    if ((typeof data[i]) == 'object') {
                        const json = JSON.stringify(data[i]);
                        const blob = new Blob([json], {
                        type: 'application/json'
                        });

                        jsonEncoded[i] = data[i];
                        
                        
                        
                    }
                    else {
                        formData.append(i, data[i]);
                    }
                }

                formData.append('__jsonEncoded', JSON.stringify(jsonEncoded));

                var res = await axios({
                    method: 'post',
                    url: process.env.VUE_APP_API_BASE+url,
                    data: formData,
                    headers: {
                        'Accept': 'application/json',
                        "Content-Type": "multipart/form-data",
                        }
                });
                
                if (res.status==419) {
                    window.location.href = '/';
                }
                
                if (res.data.error) {
                    alert(res.data.error);
                }
                
                //this.$store.dispatch('set_login', res.data.login)

                return res;
            }
            catch(e) {
                console.log("CallApi ERROR", e);
                if (e.response.status==401) {
                    // unauthorized
                    this.$router.push('/login')
                    return {data:false};
                }
                
            }
        },

        async uploadFile(url, data, file) {
            try {
                let formData = new FormData();
                formData.append("file", file);

                for (var i in data) {
                    formData.append(i, data[i]);
                }
                
                var res = await axios({
                    method: 'post',
                    url: process.env.VUE_APP_API_BASE+url,
                    data: formData,
                    headers: {
                        "Content-Type": "multipart/form-data",
                        }
                });
                
                if (res.status==419) {
                    window.location.href = '/';
                }
                
                if (res.data.error) {
                    alert(res.data.error);
                }
                
                //this.$store.dispatch('set_login', res.data.login)

                return res;
            }
            catch(e) {
                console.log("CallApi ERROR", e);
                if (e.response.status==401) {
                    // unauthorized
                    this.$router.push('/login')
                    return {data:false};
                }
                
            }
        },
        async callApi(method, url, dataObject) {
            try {
                var res = await axios({
                    method: method,
                    url: process.env.VUE_APP_API_BASE+url,
                    data: dataObject
                });
                
                if (res.status==419) {
                    window.location.href = '/';
                }
                
                if (res.data.error) {
                    //alert(res.data.error);
                    this.showTemporaryErrorMessage('Fout', res.data.error);
                }

                if (res.data.log && res.data.log.debug) {
                    console.table(res.data.log.debug)
                }
                
                if (this.$store.getters.get_lastlogin && !res.data.login) {
                    console.log("show logged out window")
                    this.showDialog({
                        title:'Uitgelogd',
                        text:'U bent uitgelogd. Log opnieuw in om verder te gaan.',
                        buttons:[
                            {label:'Inloggen', action:()=>{
                                window.location.href = '/';
                            }}
                        ]
                    
                    })
                }
                else {
                    //this.$store.dispatch('set_login', res.data.login)

                }

                return res;
            }
            catch(e) {
                console.log("CallApi ERROR", process.env.VUE_APP_API_BASE+url,  e);
                if (e.response.status==401) {
                    // unauthorized
                    this.$router.push('/login')
                    return {data:false};
                }
                else {
                    this.showTemporaryErrorMessage('Netwerkfout', 'Er is een fout opgetreden: '+e.message);
                    //alert('Er is een fout opgetreden: '+e.message)
                }
                
            }
        },

        async loadEntityForm(controller, entityType, id) {
            const res = await this.callApi('get', '/'+controller+'/getEdit/'+entityType+'/'+id);
            this._form = res.data.result.formConfig.form;
            this._formFields = res.data.result.formConfig.fields;
            this._data = res.data.result.entity;
        },

        submitForm2(id) {
            console.log('submitform', id);
            this.$formkit.submit(id);
        },

        async loadForm(loadFormConfig) {
            var endpoint = '/'+loadFormConfig.controller+'/getEdit/'+loadFormConfig.id;
            if (loadFormConfig.method) {
                endpoint = '/'+loadFormConfig.controller+'/'+loadFormConfig.method+'/'+loadFormConfig.id;
            }
            else if (loadFormConfig.compact) {
                endpoint = '/'+loadFormConfig.controller+'/getCompactEdit/'+loadFormConfig.id;
            }
            var res = false;
            if (loadFormConfig.params) res = await this.callApi('post', endpoint, loadFormConfig.params);
            else res = await this.callApi('get', endpoint);

            if (loadFormConfig.afterLoad) {
                loadFormConfig.afterLoad(res);
            }
            // ivm foutmeldingen bij getBulkMailform:
            if (res.data.error) {
                this.$emit('closeSideBar');
                return;
            }
            this._formCopy = res.data.result.formConfig;
            this._form = res.data.result.formConfig.form;
            this._formFields = res.data.result.formConfig.fields;
            this._entity = {...res.data.result.entity};

        },
        closeSidebar() {
            console.log('common closeSidebar')
            this.emitter.emit('closeSideBar');
            //this.$emit('closeSideBar')
        },
        showSuccessMessage(title, message) {
            this.emitter.emit('showAlert', {type:'success', title:title, message:message, autoclose:true});
        },
        showErrorMessage(title, message, autoclose) {
            if (!autoclose) autoclose = false;
            this.emitter.emit('showAlert', {type:'error', title:title, message:message, autoclose:autoclose});
        },
        showTemporaryErrorMessage(title, message) {
            this.emitter.emit('showAlert', {type:'error', title:title, message:message, autoclose:true});
        },
        confirmDialog(title, text, confirmAction) {
            this.showDialog({
                title:title,
                text:text,
                buttons:[
                    {label:'Annuleren'},
                    {label:'Ja', action:()=>{this.closeDialog(); confirmAction()}}
                ]
            })
        },
        showFormDialog(config) {
            var dialogConfig = {
                title:config.title,
                text:config.text,
                buttons:config.buttons,
                fields:config.fields??false
            }
            this.emitter.emit('showDialog', dialogConfig);
        },
        showDialog(config) {
            this.emitter.emit('showDialog', {title:config.title, text:config.text, buttons:config.buttons});
        },
        hideDialog() {
            this.emitter.emit('hideDialog');
        },
        closeDialog() {
            this.emitter.emit('hideDialog');
        },
        
        async updateAlertCounts() {
            //console.log('updateAlertCounts', this.$store.getters.get_login)
            if (!this.$store.getters.get_login || !this.$store.getters.get_login.id) return;
                 
            var res = await this.callApi('get', '/base/getAlertCounts');
            // //console.log('alertCounts', res.data.log.database[4]);
            if (res) this.$store.dispatch('set_alertcounts', res.data.result)
        },
        async saveEntity(controller, afterSave) {
            //debugger;
            var requestType = 'put';
            var create = false;
            if (!this._entity.id) {
                requestType = 'post';
                create = true;
            }
            this._preItemSave(this._entity);
            const res = await this.callApi(requestType, '/'+controller+'/'+(this._entity.id?this._entity.id:''), this._entity);
            
            if (!res.data.error) {
                
                this.showSuccessMessage('Opgeslagen')
            }

            if (res.data.afterSaveParams) this._afterItemSave(res.data.result, res.data.afterSaveParams)
            else {
                
                this._afterItemSave(res.data.result)
            }

            if (this._data && this._data._afterItemSaveSideBar) {
                this.$emit(
                    'showActionBar',
                    this._data._afterItemSaveSideBar.component,
                    this._data._afterItemSaveSideBar.data,
                    this._data._afterItemSaveSideBar.size    
                )
            }
            
            if (create && this._data && this._data.id) {
                
                this.$router.push('/'+controller+'/edit/'+this.__data.id);
            }
            else {
                if (afterSave && afterSave.update) {

                    var afterSaveRoute = afterSave.update;
                    
                    if (res.data.afterSaveParams) {
                        for (var key in res.data.afterSaveParams){
                            afterSaveRoute.replace(':'+key, res.data.afterSaveParams[key]);
                        }
                    }

                    this.$router.push(afterSaveRoute);
                }
            }
        }
    },
    mounted() {
        //console.log('common mounted')
        
    },
}