<template>
<div class="alignRight">
    <span v-if="header" class="tableTitleText">
        {{getEntityPropertyValue()}}
    </span>
    <span v-if="!header" class="tableEntryText">
        {{price}}
    </span>
</div>
</template>

<script>
import AbstractTableCell from './AbstractTableCell.vue';
export default {
    extends: AbstractTableCell,
    methods: {
        
    },
    computed:{
        price() {
            let val = this.getEntityPropertyValue();
            if (val) return '€ '+val.toFixed(2);
            return '';
        }
    }
}
</script>