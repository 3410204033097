import { createStore } from 'vuex'

export default createStore({
  state: {
    loaderstate: false,
    login: {},
    lastlogin: false,
    settings: [],
    stories: [],
    agecategories: {},
    traject: {}
  },
  getters: {
    get_loaderstate: state => { return state.loaderstate; },
    get_login: state => { return state.login; },
    get_lastlogin: state => { return state.lastlogin; },
    get_settings: state => { return state.settings; },
    get_stories: state => { return state.stories; },
    get_agecategories: state => { return state.agecategories; },
    get_traject: state => { return state.traject; }
  },
  mutations: {
    SET_LOADERSTATE(state, val) { state.loaderstate = val; },
    SET_LOGIN(state, val) { state.login = val; },
    SET_LASTLOGIN(state, val) { state.lastlogin = val; },
    SET_SETTINGS(state, val) { state.settings = val; },
    SET_STORIES(state, val) { state.stories = val; },
    SET_AGECATEGORIES(state, val) { state.agecategories = val; },
    SET_TRAJECT(state, val) { state.traject = val; }
  },
  actions: {
    set_loaderstate({ commit }, val) { commit('SET_LOADERSTATE', val); },
    set_login({ commit }, val) { commit('SET_LOGIN', val); },
    set_lastlogin({ commit }, val) { commit('SET_LASTLOGIN', val); },
    set_settings({ commit }, val) { commit('SET_SETTINGS', val); },
    set_stories({ commit }, val) { commit('SET_STORIES', val); },
    set_agecategories({ commit }, val) { commit('SET_AGECATEGORIES', val); },
    set_traject({ commit }, val) { commit('SET_TRAJECT', val); }
  }
})