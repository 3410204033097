<template>
    <!-- <label>{{$props.context.label}}</label> -->
    <div class="autoComplete">
        <!-- <Tree
            :nodes="tree.nodes"
            :config="tree.config"
            @nodeFocus="treeClick"
            ></Tree> -->

            <multiselect
                v-model="selectedValues"
                :options="options"
                label="name"
                track-by="id"
                placeholder=""
                selectLabel="Druk op enter om te selecteren"
                deselectLabel="Druk op enter om te verwijderen"
                selectedLabel="Geselecteerd"
                :show-no-options="false"
                @search-change="search"
                :multiple="true">
                <template #noResult>Geen opties gevonden</template>
                </multiselect>

    </div>
</template>

<script>
  import Multiselect from 'vue-multiselect'
  import "vue-multiselect/dist/vue-multiselect.css";
import { ref } from 'vue';

export default {
  components: {Multiselect},
  props: {
      context: {
          type:Object,
          required:true
      },
    //   nodes: {
    //       type:Object,
    //       required:true
    //   }
  },
  data() {
      return {
        selectedValues:[],
        options:[],
      }
  },
  methods: {
      
      handleInput(e) {
          console.log('handleInput', e)
            //this.$props.context.node.input(e.level.content)

      },
      async search(e) {
          console.log('search', e)
          return;
          const res = await this.callApi('post', '/search', {search:e});
          console.log(res.data.result)
          this.options = res.data.result;
      },
      
  },
  computed: {
    values() {
        var a = [];
        this.selectedValues.forEach(id=>{
            a.push({id:id, name: this.options.find(o=>o.id==id).name})
            //a.push(id)
        })
        return a;
    }
  },
  watch: {
      selectedValues(newVal) {
        console.log('watch', newVal)
          this.valueCopy = newVal;
          var a = [];
            this.selectedValues.forEach(option=>{
                
                a.push(option.id)
            })
          this.$props.context.node.input(a);
      }
  },
  created() {
        //console.log('autocomplete created', this.$props.context._value)
        this.options = this.$props.context.attrs.options;
        var a = [];
        if (!this.$props.context._value || this.$props.context._value == undefined)    return;
        this.$props.context._value.forEach(id=>{
            console.log(id)
            a.push({id:id, name: this.options.find(o=>o.id==id).name})
        })
        this.selectedValues = a;

  },
  beforeMount() {
        //console.log('autocomplete beforeMount', this.$props.context._value)

  },
  beforeUnmount() {
        //console.log('autocomplete beforeUnmount', this.$props.context._value)
        // this.autocomplete.nodes = {};
        // this.autocomplete.config.roots = [];
        // this.autocomplete.config.leaves = [];
        // this.$props.context.attrs.nodes =[]
  },
  mounted() {
        console.log('autocomplete mounted', this.$props.context.attrs.options)
        
        //debugger;
        
  }
}
// const props = defineProps({
//     context: Object,
// })
// console.log(props)
// var testval = ref(props.context.value)
// function handleInput(e) {
//     console.log(props)
//     console.log(e)
//     testval = e.level.content
//     //props.context.node.input(e.target.value)
//     //props.context.node.input(e.level.content)
//     //props.context.node.input('234')
// }

</script>


<style>
.autoComplete .multiselect__input {
    background: none !important;
    border: none !important;
}
.autoComplete .multiselect__tags {
    border: 1px solid #cec5d5;
    width: 100%;
    padding: 1rem 1.8rem;
    background: #faf9fb;
    color: #310e4e;
    
    border-radius: 10px;
    -webkit-appearance: none;
    
    font-size: 1.4rem;
    font-family: "Lexend",sans-serif;
    line-height: 1.555;
    outline: none;

}

</style>