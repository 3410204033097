<template>
<!-- <div class="tableCell" :class="isMarked()?'marked':''" :style="{ width: getColumnWidth() + 'px' }"> -->
<div :class="{'alignRight':alignRight()}">

    <span v-if="!header && hasLink()" class="tableEntryText cursor"  :class="{'marked':isMarked()}" @click="linkFunction"><a v-html="getEntityPropertyValue()"></a></span>
    <span v-if="!header && !hasLink()" class="tableEntryText"  :class="{'marked':isMarked()}" v-html="getEntityPropertyValue()" @click="linkFunction"></span>
    <span v-if="header" :class="{overviewTitleText:isOverviewTable, tableTitleText:!isOverviewTable}" v-html="getEntityPropertyValue()"></span>
</div>
</template>

<script>
import AbstractTableCell from './AbstractTableCell.vue';
export default {
    extends: AbstractTableCell,
    methods: {
        
    }

}
</script>