<template>
    <div v-if="!item._hidden" class="tableEntryXLWrap">
    
        <div class="tableEntryXL" v-bind:style="{'grid-template-columns': gridColumnWidths}">
            <template v-for="column in columns">
                <component :is="column.component" :item="item" :column="column" />
            </template>
        </div>
        
                    
        <StripTable v-if="!item._hidden && item._collapsed"
            :items="getActivities"
            :columns="stripcardColumns"
            :tableRow="getTableRowComponent()"
        ></StripTable>
        
    
    </div>
</template>
    
<script>


import { shallowRef} from 'vue'

import AppTableRow from '../../../components/base/Table/TableRow';
import AppTableCellCenter from '../../../components/base/Table/TableCellCenter';
import StripTable from '../../../components/custom/Stripcard/Table';
import ActivityTableRow from '../../../components/custom/Stripcard/ActivityTableRow';

import AppTableCell from '../../../components/base/Table/TableCell';

import AppTableCellDate from '../../../components/base/Table/TableCellDate';
import DetailsSideBar from '../../../components/base/Sidebar/Details';

//import AbstractTableRow from './TableRow.vue';

export default {
    extends:AppTableRow,
    emits:['showNotes','showSidebar','showActionBar'],
    components:{StripTable},
    data() {
        return {
            gridColumnWidths: '6rem 1fr 1fr 1fr',
            stripcardColumns:[
                // {width:'5.6rem', title:'-', component:shallowRef(AppTableCellCollapse), collapseAll:this.collapseAll},
                
                {width:'13rem', name:'date', title:'Datum', component:shallowRef(AppTableCellDate)},
                {width:'10rem', name:'User.firstname', title:'Contact', component:shallowRef(AppTableCell)},
                {width:'13rem', name:'amount', title:'Strippen', component:shallowRef(AppTableCellCenter)},
                {width:'4fr', name:'description', title:'Omschrijving', component:shallowRef(AppTableCell), formatFunction:item=>{return '<strong>'+item.description+'</strong>'+(item.details?'<br />'+item.details:'')}},
            ]
        }
    },
        computed: {
            getActivities() {
                return this.$props.item.Stripactivities.sort((a,b)=>{
                    return new Date(b.date) - new Date(a.date);
                })
            },
        },
        methods: {
            getTableRowComponent() {
                //return null;
                return ActivityTableRow;
                //return EnrollmentTableRow;
            },
        },
        props: {
            gridColumnWidths: {
                type: String,
                required:true
            },
            item: {
                type: Object,
                required: true
            },
            columns: {
                type: Array,
                required: true
            }        
        },
        mounted() {
    
        }
    }
    </script>