<template>
  <div class="sideBar">
    <header class="sideBarHeader">
      <h2 class="sideBarHeaderTitle">{{editTitle}}</h2>
      
      <span class="sideBarHeaderSubtitle cursor" v-html="editName" ></span>

      <a @click="$emit('closeSideBar')" class="sideBarCloseIcon">
        <i class="fa-solid fa-xmark"></i>
      </a>
    </header>


    

    <div class="panel">
			<div class="panelSubtitleWrap">
				<h3 class="panelSubtitle">{{editSubTitle}}</h3>
				<div class="panelSubtitleLinks">
					<a v-if="showFullEditLink" class="panelSubtitleLink fullscreen cursor" @click="openEditDetails">Volledige weergave</a><!--  <a @click="openEditDetails" class="panelSubtitleLink edit cursor">Wijzig klant</a> -->
				</div>
			</div>
			
		</div>


<!--       
        <div class="panelForm">
      <div class="formkit-form">
        <div class="panelSubtitleWrap">
          <h3 class="panelSubtitle">{{editSubTitle}}</h3>
          <a class="panelSubtitleLink cursor" @click="openEditDetails">Open in volledige weergave</a>
          </div>
          </div>
          </div> -->
    <div class="panelForm">
      
      <FormKit type="form" v-model="_entity" :actions="false" @submit="save">
        <FormKitSchema :schema="_formFields" />
      </FormKit>
    </div>
  </div>
</template>

<script>

export default {
    emits:['showActionBar', 'closeSideBar','showAlert'],
    props: {
        _data: {type:Object, required:true}
    },
    data() {
        return {
          //__data:{}
        }
    },
    computed: {
        showFullEditLink() {
            switch(this._data._class) {
                case 'Mail':
                case 'MailTemplateBlock':
                case 'BaseCourseAction':
                    return false;
                    break;
                default:
                    return true;
                    break;
            }
        },
        editSubTitle() {
            var html = [];
            var entityName = this.getEditSubTitle();
            
            html.push(entityName);
            return html.join('<br />');
        },
        editTitle() {
            var html = [];

            var entityName = this.getEditTitle(this._data);
            
            if (this._data.id) html.push(entityName+' aanpassen');
            else html.push(entityName+' aanmaken');
            return html.join('<br />');
        },
        editName() {
            if (!this._data.id) return '';
            var html = [];

            
            switch(this._data._class) {
                case 'BaseCourse':
                case 'Course':
                case 'CourseLocation':
                case 'Customer':    
                    html.push(this._data.name+' - ID: '+this._data.id);
                    break;
                case 'Participant':    
                    html.push(this._data.fullname+'  ('+this._data.Customer.name+')');
                    break;
                case 'User':    
                    html.push(this._data.fullname+' - ID: '+this._data.id);
                    break;
                case 'MailTemplate':    
                    html.push((this._data.BaseCourse.acronym?this._data.BaseCourse.acronym:'Default')+(this._data.type?' - '+this._data.type+' template':''));
                    break;
                case 'Enrollment':    
                    html.push(this._data.Participant.fullname+'  ('+this._data.Customer.name+')');
                    break;
                case 'BaseCourseAction':    
                    html.push(this._data.BaseCourse.name);
                    break;
            }
            return html.join('<br />');
        },
        
    },
    methods: {
      openEditDetails() {
        if (this.$props._data.editRoute) this.$router.push(this.$props._data.editRoute+this.$props._data.id);
        else {
          let customPath = this.getCustomPath('edit', this.$props._data.controller, this.$props._data.id);
          if (customPath) {
            this.$router.push(customPath);
            return;
          }
          this.$router.push('/'+this.$filters.pathControllerAlias(this.$props._data.controller)+'/edit/'+this.$props._data.id);
        }
      },
      async save() {
          //this.$props._data.name="yyy"
          this.saveEntity(this.$props._data.controller);
          this.$emit('closeSideBar');
      },

    },
    watch: {
        '$route' (to, from) {
            //console.log(to.path,this.$route.path)
            if (this.$props._data.controller) {
                console.log('init edit '+this.$props._data.controller)
                this.loadForm({
                    controller: this.$props._data.controller,
                    id: (this.$props._data.id?this.$props._data.id:0),
                    compact: true
                });
                if (this.$props._afterItemSave) this._afterItemSave = this.$props._afterItemSave;
                else {this._afterItemSave = ()=>{console.log('default _afterItemSave1')}}
            }
        }
    },
    mounted() {
      console.log(this.$props._data);
      console.log('mounted edit ', this.$props)
      this.loadForm({
          controller: this.$props._data.controller,
          id: (this.$props._data.id?this.$props._data.id:0),
          compact: true,
          afterLoad: (data)=>{
            console.log('afterload', this.$props._data, data)
            if (this.$props._data.setEntityValues) {
              for (const i in this.$props._data.setEntityValues) {
                console.log(i, this.$props._data.setEntityValues[i])
                data.data.result.entity[i] = this.$props._data.setEntityValues[i]
                // this.$props._data[i] = this.$props._data.setEntityValues[i]
              }
            }
            this.hideLoader();
          }
      });
      
      if (this.$props._data._afterItemSave) this._afterItemSave = this.$props._data._afterItemSave;
      else {this._afterItemSave = ()=>{console.log('default _afterItemSave2')}}
    }
}
</script>

<style >
.editDetailsLink {
  float:right;
  display:block;
  cursor: pointer;
}


</style>