<script>
export default {
    functional:true,
    props: {
        // field: {
        //     type: String,
        //     required: true
        // },
        column: {
            type: Object,
            required: true
        },
        // column: {
        //     type: Object,
        //     required: true
        // },
        header: {
            type: Boolean,
            required: false
        },        
    
        item: {
            type: Object,
            required: false
        },
        isOverviewTable: {
            type: Boolean,
            required:false
        }
    },
    methods: {
      linkFunction() {
        if (this.$props.column.link) {
            this.$props.column.link(this.$props.item)
        }
      },
      headerClickFunction() {
        if (this.$props.column.headerClick) {
            this.$props.column.headerClick(this.$props.column)
        }
      },
      alignRight() {
        if (this.$props.column.align && this.$props.column.align=='right') return true;
        return false;
      },
      hasLink() {
        return this.$props.column.link?true:false
      },
      headerHasClickFunction() {
        return this.$props.column.headerClick?true:false
      },
      isMarked() {
          if (this.$props.item && this.$props.item._markMatches) {
            //console.log('isMarked', this.$props.item._markMatches)
            if (this.$props.item._markMatches.includes(this.$props.column.name)) return true;
          }
          return false;
      },
      getColumnWidth() {
          var width = 50;
          if (this.$props.column.width) {
              width = this.$props.column.width;
          }
        //   this.$props.columns.forEach(column => {
        //       if (column.name==this.$props.field) {
        //           width = column.width;
        //       }
        //   });
          return width;
      },
      setValue(val) {
          this.$props.item[this.$props.column.name] = val;
      },
      getColumnHeader() {

          return this.$props.column.title;
      },
      getEntityPropertyValue() {
          //console.log(this.$props.column.name)
          if (this.header) {
              return this.getColumnHeader();
          }
          if (this.$props.column.formatFunction) {
            return this.$props.column.formatFunction(this.item)
          }
          if (this.$props.column.name) {
              var obj = this.item;
              var path = this.$props.column.name.split('.');
              var value = '';
              path.forEach(pathName => {
                  let matches = /([A-Za-z]+)\[([0-9]+)\]/.exec(pathName);
                
                  //console.log(obj, pathName);
                  if (matches && matches[1] && matches[2]) {
                      ///console.log('matches', obj, obj[matches[1]]);
                      
                      if (obj[matches[1]] && obj[matches[1]][matches[2]]) {

                          obj = obj[matches[1]][matches[2]];
                      }
                      else {
                          obj = '';
                      }
                  }
                  else if (obj[pathName]) {
                      //return obj[pathName];
                      obj = obj[pathName];
                      //console.log(obj);
                  }
                  else {
                      //console.log("cannot find prop: ", pathName)
                      obj = '';;
                  }
              });
              return obj;
              
          }
          return this.item;
      },
    }
}
</script>