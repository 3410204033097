<template>
<div class="tableTitleText">
    
    <template v-if="header">    
        <a v-if="collapseState" @click="collapse(false)" class="overviewTitleLink cursor">
            <i class="fa-solid fa-square-caret-down"></i>
        </a>
        <a v-else @click="collapse(false)" class="overviewTitleLink cursor">
            <i class="fa-solid fa-square-caret-up"></i>
        </a>
        <!-- <span v-if="collapseState" @click="collapse(false)">⇩</span>
        <span v-else @click="collapse(true)">⇧</span> -->
    </template>
    <template v-if="!header">
        <a v-if="getEntityPropertyValue()" @click="collapse(fatruelse)" class="tableEntryLinkIcon">
            <i class="fa-solid fa-square-caret-down"></i>
        </a>
        <a v-else @click="collapse(true)" class="tableEntryLinkIcon">
            <i class="fa-solid fa-square-caret-up"></i>
        </a>
        <!-- <a href="#" class="overviewTitleLink"><i class="fa-solid fa-square-caret-down"></i></a> -->
        <!-- <span v-if="getEntityPropertyValue()" @click="collapse(false)">⇩</span>
        <span v-else @click="collapse(true)">⇧</span> -->
    </template>
    <!-- <template v-if="header">    
        <span v-if="collapseState" @click="collapse(false)">⇩</span>
        <span v-else @click="collapse(true)">⇧</span>
    </template>
    <template v-if="!header">
        <span v-if="getEntityPropertyValue()" @click="collapse(false)">⇩</span>
        <span v-else @click="collapse(true)">⇧</span>
    </template> -->
</div>
</template>

<script>
import AbstractTableCell from './AbstractTableCell.vue';
export default {
    extends: AbstractTableCell,
    data() {
        return {
            collapseState:false
        }
    },
    methods: {
        collapseAllMethod(collapsed) {
            if (this.$props.column.collapseAll) {
                this.$props.column.collapseAll(collapsed);
            }
        },
        collapse(val) {
            if (this.header) {
                this.collapseState =  !this.collapseState;
                this.collapseAllMethod(this.collapseState)
            }
            else {
                this.$props.item._collapsed = val;
                
            }
        },
        getEntityPropertyValue() {
          if (this.$props.item && this.$props.item._collapsed) return true;
          return false;
        }
    }
}
</script>

