import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'



import common from './tools/common'
import custom from './tools/custom'
//import { plugin, defaultConfig } from '@formkit/vue'
//import moveLabelPlugin from './tools/formkit.config'


import DisplayField from './components/base/Formkit/Display'
import WysiwygField from './components/base/Formkit/Wysiwyg'
//import TreeField from './components/base/Formkit/Tree'
import AutoCompleteField from './components/base/Formkit/Autocomplete'
import AutoCompleteMultipleField from './components/base/Formkit/AutocompleteMultiple'
import FileBrowser from './components/base/Formkit/FileBrowser'
import Editor from '@tinymce/tinymce-vue'

import mitt from 'mitt'

// FORMKIT
import { plugin, defaultConfig, createInput } from '@formkit/vue'
import { FormKitSchema } from '@formkit/vue'
import { en,nl } from '@formkit/i18n'
import { applicationIcons } from '@formkit/icons'


import { createMultiStepPlugin } from '@formkit/addons'
import '@formkit/addons/css/multistep'



import moveLabelPlugin from './moveLabelPlugin'
import addWrapperPlugin from './addWrapperPlugin'
import { text } from '../src/inputs/text'
//import { select } from '../src/inputs/select'
import { checkbox } from '../src/inputs/checkbox'

import { addselect } from '../src/inputs/addselect'


//import '@formkit/themes/genesis'
//import '@formkit/pro/genesis'

//import { plugin, defaultConfig } from '@formkit/vue'
//import { createProPlugin, inputs } from '@formkit/pro'

//const pro = createProPlugin('fk-2a617d357', inputs)
//app.use(plugin, defaultConfig({ plugins: [pro] }))






import './assets/css/layout.css';
import './assets/css/custom.css';


// import DropZone from 'dropzone-vue';

// // optionally import default styles
// import 'dropzone-vue/dist/dropzone-vue.common.css';

const emitter = mitt()

const app = createApp(App)

app.config.globalProperties.emitter = emitter

app.mixin(common);
app.mixin(custom);
app.use(store);
app.use(router);
//app.use(DropZone);


import { SetupCalendar } from 'v-calendar';


// Setup plugin for defaults or `$screens` (optional)
app.use(SetupCalendar, {})

// import { createProPlugin, inputs } from '@formkit/pro'
// const pro = createProPlugin('fk-2a617d357', inputs)
// //import '@formkit/themes/genesis'
// import '@formkit/pro/genesis'
// app.use(plugin, defaultConfig({ plugins: [pro] }))




// app.filter('formatDate', function(value) {
//   if (value) {
//       return moment(String(value)).format('MM/DD/YYYY HH:mm')
//   }
// });
//app.use(vueFaker);

// Define your FormKit global config/plugins here:
app.use(
  plugin,
  defaultConfig({
    // Define additional locales
    locales: { en,nl },
    // Define the active locale
    locale: 'nl',
    icons: {
      ...applicationIcons
    },
    inputs: {
      display: createInput(DisplayField, {
        //props: ['digits'],
      }),
      wysiwyg: createInput(WysiwygField, {
        //props: ['digits'],
      }),
      // tree: createInput(TreeField, {
      //   //props: ['digits'],
      // }),
      autocomplete: createInput(AutoCompleteField, {
        //props: ['digits'],
      }),
      autocomplete_multiple: createInput(AutoCompleteMultipleField, {
        //props: ['digits'],
      }),
      filebrowser: createInput(FileBrowser, {
        //props: ['digits'],
      }),
      editor:Editor,
      //checkbox
      //text
      addselect
    },
    plugins: [moveLabelPlugin, createMultiStepPlugin()]
}));


app.config.globalProperties.$filters = {
  pathControllerAlias(controller) {
    switch(controller) {
      case 'participants': return 'students'; break;
      default: return controller;
    }
  },
  nl2br(str) {
    if (typeof str === 'undefined' || str === null) {
      return '';
    }
    var breakTag = '<br />';
    return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
  },
  formatPrice(value) {
    if (!value) return '-';
    value = parseFloat(value).toFixed(2).toString();
    var e = value.split('.');
    if(e[0].length>3) { 
      e[0] = e[0].substring(0,e[0].length-3)+'.'+e[0].substring(e[0].length-3,e[0].length);
    }
    if (e.length==2) {
      return '€ '+e[0]+','+e[1];
    }
    else {
      return '€ '+e[0]+',00';
    }
  },
  formatDate(value) {
    if (!value) return '-';
    var e = value.split(' ');
    if (e.length==2) {
      var e2 = e[0].split('-');
      var e3 = e[1].split(':');
      return e2[2]+'-'+e2[1]+'-'+e2[0]+', '+e3[0]+':'+e3[1]+' uur';
    }
    else if (e.length==1) {
      var e2 = value.split('-');
      return e2[2]+'-'+e2[1]+'-'+e2[0];
    }
    else {
      //console.log(e.length)
      return '-';
    }
    
  }
}

app.mount('#app')



//
//App.mixin(common);

// createApp(App).mixin(common).use(plugin, defaultConfig({
//     plugins: [moveLabelPlugin]
//   })).use(store).use(router).mount('#app')

