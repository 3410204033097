<template>

    <div class="loader" :class="{active:loaderState}">
      <i class="fa-solid fa-spinner-third fa-spin"></i>
    </div>
  
    <dialog class="modal" id="dialog">
      <form @submit.prevent="()=>{}">
          <h2 v-if="dialog && dialog.title" class="modalTitle">{{dialog.title}}</h2>
          <p v-if="dialog && dialog.text" v-html="dialog.text"></p>
  
          <!-- <FormKit v-if="dialog.fields && dialog.fields.length" type="form" v-model="dialogForm" :actions="false"> -->
          <FormKit v-if="dialog.fields && dialog.fields.length" type="form" v-model="dialogForm" :actions="false" @submit="dialog.buttons[dialog.buttons.length-1].action(this.dialogForm)">
              <FormKitSchema :schema="dialog.fields" />
          </FormKit>
  
          <div class="modalButtons" v-if="dialog">
              
            <template v-for="button in dialog.buttons">
                <button v-if="!button.action" class="modalButton modalButtonLine" @click="this.closeDialog">{{ button.label }}</button>
                <button v-else class="modalButton" :class="button.class" @click="()=>{button.action(this.dialogForm)}">{{ button.label }}</button>
            </template>
              <!-- <button class="modalButton modalButtonLine">Yup</button>
              <button class="modalButton modalButtonContrast">100%</button>
              <button class="modalButton">Jazeker</button> -->
          </div>
      </form>
    </dialog>
    
    <div class="alerts">
          
  
          <div v-for="alert in alerts" class="alert" :class="{debug:debugMode, alertSuccess:alert.type=='success', alertError:alert.type=='error', active:alert.active}">
              <div class="alertInfo">
                  <p class="alertTitle">{{alert.title}}</p>
                  <p class="alertMessage" v-html="$filters.nl2br(alert.message)"></p>
              </div>
              <a v-if="alert.title && alert.type=='error'" @click="hideAlert(alert.id)" class="alertClose cursor"><i class="fa-solid fa-xmark"></i></a>
          </div>
          
          
      </div>

      <!-- {{ isLoggedIn }}
      {{ loginValues }} -->
    <div class="loginScreen">
      <figure class="loginLogo">
        <img :src="logoDefault" :alt="companyName+' logo'" />
      </figure>

      <header class="mainHeader"><h1>{{customerName}}</h1></header>

      <div class="loginPanel">

        <section class="mainSubnav">
          <nav>
            <a v-for="menuItem in menuItems" @click="setActiveMenu(menuItem)" class="cursor" :class="{active:menuItem.active, 'router-link-exact-active':menuItem.active}">{{ menuItem.label }}</a>
            
          </nav>
        </section>

        <template v-if="activeMainMenu=='domains'">
          <!-- <h1>{{ customerName }} - geregistreerde domeinen</h1> -->
          <AppTable
            :items="domains"
            :columns="domainColumns"
            :tableRow="getTableRowComponent()"  
          ></AppTable>
        </template>

        <template v-if="activeMainMenu=='services'">
          <!-- <h1>{{ customerName }} - diensten</h1> -->
          <AppTable
            :items="services"
            :columns="serviceColumns"
            :tableRow="getTableRowComponent()"  
          ></AppTable>
        </template>

        <template v-if="activeMainMenu=='stripcards'">
          <!-- <h1>Strippenkaarten</h1> -->
          
          <AppTable
            :items="stripcards"
            :columns="columns"
            :tableRow="getTableRowComponent()"
            :isOverviewTable="true"
            @show-notes="showNotes"
            @show-sidebar="showSidebar"
            
          ></AppTable>
        </template>

        <template v-if="activeMainMenu=='contact'">
          <h1>Contact informatie</h1>
        </template>
        
      </div>
      
    </div>
  
   
  
  </template>
  
  <script>
  import { classExpression, tSExpressionWithTypeArguments } from '@babel/types';
  import { useRouter, useRoute } from 'vue-router'

  import { shallowRef} from 'vue'
  import AppTable from './components/base/Table/Table';
  import AppTableCellCollapse from './components/base/Table/TableCellCollapse';
  import AppTableCellprice from './components/base/Table/TableCellPrice';
  import AppTableCellDate from './components/base/Table/TableCellDate';
  import AppTableRow from './components/custom/Stripcard/TableRow';

  import AppTableCell from './components/base/Table/TableCell';
  
  export default {
    emits:['showActionBar','closeSideBar','showAlert'],
    components:{AppTable,AppTableRow},
    data() {
      return {
        customerName: '...',
        baseColor: "blue",
        baseColor1: '#ff6600',
        debugMode:false,
        alerts: [],
        dialog: false,
        leftMenu:{
          open:1
        },
        menuItems: [
          //{name:'domains', label:'Domeinen', active:true},
          //{name:'services', label:'Diensten', active:false},
          {name:'stripcards', label:'Strippenkaarten', active:true},
          //{name:'contact', label:'Contact', active:false},
        ],
        
        items:[],
        domains:[],
        services:[],
        columns:[
            {width:'5.6rem', title:'-', component:shallowRef(AppTableCellCollapse), collapseAll:this.collapseAll},
            {width:'3fr', name:'id', title:'Gebruikt', component:shallowRef(AppTableCell), formatFunction:item=>{
              let label = '';
              if (item.used>=item.strips) label = '<span class="statusLabel disabled">Vol</span>';
              let paidLabel = '';
              if (item.paid) paidLabel = '<span class="statusLabel success">Betaald</span>';
              return item.used+' / '+item.strips+' strippen '+label+paidLabel;
            }},
            {width:'3fr', name:'id', title:'Strippenkaart', component:shallowRef(AppTableCell), formatFunction:item=>{
              return item.strips+' strippen'; // removed price
              return item.strips+' strippen - '+this.$filters.formatPrice(item.price);
            }},
            {width:'1fr', name:'create', title:'Aangemaakt', component:shallowRef(AppTableCell), formatFunction:item=>{
              return this.$filters.formatDate(item.date.substr(0,10));
            
            }},
        ],
        domainColumns:[
          {width:'3fr', name:'domain', title:'Domeinnaam', component:shallowRef(AppTableCell)},
          {width:'1fr', name:'type', title:'Type', component:shallowRef(AppTableCell)},
          //{width:'1fr', name:'price', title:'Prijs', component:shallowRef(AppTableCellprice)}
        ],
        serviceColumns:[
          {width:'2fr', name:'Service.name', title:'Dienst', component:shallowRef(AppTableCell), formatFunction:item=>{
            if (item.discount_percentage) {
              return item.Service.name+'<br /><span class="statusLabel disabled">Korting '+item.discount_percentage+'%</span>';
            }
            return item.Service.name;
          }},
          {width:'3fr', name:'description', title:'Omschrijving', component:shallowRef(AppTableCell)}
        ],
        sideBar: {
          size:'small',
          component:false,
          data:false
        },
        dialogForm:{}
        //sideBarComponent: false,
        //sideBarComponentData: false
      }
    },
    methods: {
      setActiveMenu(menuItem) {
        this.menuItems.forEach(item=>{
          item.active=false;
          if (item.name==menuItem.name) item.active=true;
        })
      },
      
      getTableRowComponent() {
        return AppTableRow;
      },
      collapseAll(collapsed) {
          this.items.map(course=>course._collapsed=collapsed)
      },
  
    //   async verify2fa() {
    //       const res = await this.callApi('post', '/login/verify2fa', {
    //           login2facode:this.loginValues.login2facode
    //       });
    //       if (res.data.result) {
    //         this.$router.push(process.env.VUE_APP_DEFAULT_ROUTE);
    //         this.clearAlerts();
    //         this.showAlert('success', '2FA', "2FA succesvol voltooid!")
    //         this.reloadStores()
    //       }
    //       else {
    //         this.showAlert('error', 'Fout', "2FA code onjuist")
    //       }
    //   },
    //   async setup2fa() {
    //       const res = await this.callApi('post', '/login/setup2fa', {
    //           setup2facode:this.loginValues.setup2facode
    //       });
    //       if (res.data.result) {
    //         this.$router.push(process.env.VUE_APP_DEFAULT_ROUTE);
    //         this.clearAlerts();
    //         this.showAlert('success', '2FA', "2FA succesvol ingesteld!")
    //       }
    //       else {
    //         this.showAlert('error', 'Fout', "2FA code onjuist", true)
    //       }
    //   },
  
      async login() {
          const res = await this.callApi('post', '/frontend/login', {
              email:this.loginValues.email,
              password:this.loginValues.password
          });
  
          if (res.data.result) {
            
            this.$router.push(process.env.VUE_APP_DEFAULT_ROUTE);
            this.clearAlerts();
            this.showAlert('success', 'Login', "Succesvol ingelogd!")
            this.$router.push('/opdracht');
            //this.reloadStores()

            this.$store.dispatch('set_lastlogin', Math.floor(Date.now() / 1000))

            this.$store.dispatch('set_login', res.data.result)
            
          }
          else {
              //alert("Wrong email / password")
              //this.showAlert('error', 'Fout', "Login onjuist", true)
          }
      },
      async submitForgotPassword() {
          const res = await this.callApi('post', '/frontend/forgotPassword', {
              email:this.loginValues.email
          });
          this.showAlert('success', 'Nieuw wachtwoord', "Nieuw wachtwoord aangevraagd, controleer uw email");
          //this.loginValues.forgotPassword=false;
          this.loginValues.loginType='login';
      },
      async submitNewPassword() {
        console.log(this.loginValues.newpassword, this.loginValues.newpassword.length)
            if (!this.loginValues.newpassword) {
                this.showTemporaryErrorMessage('Fout', "Vul een nieuw wachtwoord in");
                return;
            }
            if (this.loginValues.newpassword.length < 6) {
                this.showTemporaryErrorMessage('Fout', "Wachtwoord moet minimaal 6 tekens bevatten");
                return;
            }
            if (this.loginValues.newpassword != this.loginValues.newpassword_confirm) {
                this.showTemporaryErrorMessage('Fout', "Wachtwoorden komen niet overeen");
                return;
            }
            const res = await this.callApi('post', '/frontend/setNewPassword', {
                    newpassword:this.loginValues.newpassword,
                    password_confirm:this.loginValues.newpassword_confirm,
                    id:this.$route.params.id,
                    hash:this.$route.params.validationToken,
            });
            
            this.loginValues.newpassword = '';
            this.loginValues.newpassword_confirm = '';
            if (!res.data.error) {
                this.showAlert('success', 'Nieuw wachtwoord', "Nieuw wachtwoord opgeslagen, u kunt nu inloggen");
            }
            this.loginValues.loginType='login';
            this.$router.push('/login');
      },
      clearAlerts() {
        this.alerts = [];
      },
      hideAlert(id) {
        //console.log('hide', id)
        this.alerts.forEach(alert=>{
          if (alert.id==id) {
            alert.active=false;
            setTimeout(() => {
              this.removeAlert(id)
            }, 2000);
          }
        })
      },
      removeAlert(id) {
        this.alerts = this.alerts.filter(function( alert ) {
            return alert.id !== id;
        }); 
      },
      showAlert(type, title, message, autoclose) {
        console.log('showAlert', type, title, message, autoclose)
        var id = Math.floor(Math.random() * 1000000000);
        this.alerts.push({
            'id':id,
            'type':type,
            'title':title,
            'active':true,
            'message':message
        })
  
        if (type=='success' || autoclose) {
          setTimeout(() => {
            this.hideAlert(id)
          }, 2000);
        }
      },
      async loadServices(path) {
        const res = await this.callApi('post', '/frontend/getServices', {
            path:path
        });
        console.log('loadServices', res)

        this.customerName = res.data.result.customer.name;
        this.domains = res.data.result.domains;
        this.services = res.data.result.services;
        //this.items = res.data.result;
        
      },
      async loadStripcards(path) {
        const res = await this.callApi('post', '/frontend/getStripcards', {
            path:path
        });
        console.log('loadStripcards', res)
        this.items = res.data.result;
        
      },
      
  
      closeSideBar() {
        //console.log('closeSideBar')
        this.sideBar.component=false;
        this.sideBar.data = false;
      },
      showActionBar(component, data, size) {
        //console.log("showActionBar", data)
        if (this.sideBar.component) {
          this.closeSideBar()
          var that = this;
          setTimeout(function(){
            that.showActionBar(component, data, size)
          }, 200)
          return;
        }
        this.sideBar.size = size;
        this.sideBar.component = component;
        this.sideBar.data = data;
      },
      isActiveMainMenu(route) {
        if (this.$route.path=='/') return false;
  
        var mainSection = this.$route.path.split('/')[1];
        if (route.path=='/'+mainSection) {
          //console.log(route.path, mainSection);
          return true;
        }
        return false;
      }
    },
    watch: {
      '$route' (to, from) {
          //console.log(to,from)
          if (to.path=='/') {
            this.$router.push(process.env.VUE_APP_DEFAULT_ROUTE)
            return
          }
          //to.loaded();
          this.closeSideBar()
          //this.updateAlertCounts()
      }
    },
    computed: {
      activeMainMenu() {
          return this.menuItems.filter(item=>{return item.active})[0].name;
      },

      stripcards() {
        return this.items.sort((a,b)=>{
          if (b.date == a.date) {
            // same date
            return -a.open<-b.open?-1:1;
          }
          return new Date(b.date) - new Date(a.date);
          return -a.open<-b.open?-1:1;
          if (a.open && !b.open) { return -1; }
          return 0;
          if (a.open && b.open) return 1
          return a.used<b.used?1:-1
          //else return -1
          // else {
          //   if (a.open>b.open) return 1
          //   else return -1
          // }
          return b.open - a.open;
          // return a.open - b.open;
          // return a.create - b.create;
        })
      },
      servicesName() {
      },
      stripcardsName() {
        if (this.items.length) {
          return this.items[0].Customer.name+' - Overzicht strippenkaarten';
        }
        return 'Strippenkaarten'
      },
      cssVars() {
        return {
          'baseColor1': '#310E4E'
        }
      },
  
      logoDefault() { return process.env.VUE_APP_LOGO_DEFAULT; },
      logoFull() { return process.env.VUE_APP_LOGO_FULL; },
      logoCollapsed() { return process.env.VUE_APP_LOGO_COLLAPSED; },
      companyName() { return process.env.VUE_APP_COMPANY_NAME; },
  
      loaderState() {
        //console.log('loaderState', this.$store.getters.getLoaderState)
        return this.$store.getters.get_loaderstate?true:false
      },
      mainWrapClass() {
        var c = [];
        var routes = this.$router.options.routes.filter(route=>{return route.name==this.$route.name});
        if (!this.leftMenu.open) c.push('collapsed');
        if (routes.length==1 && routes[0].className) c.push(routes[0].className);
        return c.join(' ');
      },
      sidebarClass() {
        if (this.sideBar.component) {
          var classes = ['sideBarWrap'];
          if (this.sideBar.size!='large') {
            classes.push('actionsSideBar');
          }
          classes.push('active');
          return classes.join(' ');
        }
        return 'sideBarWrap';
      },
      sidebarSize() {
        if (this.sideBar.component) {
            if (this.sideBar.size=='large') {
              return '600px';
            }
            else {
              return '400px';
  
            }
        }
        return '0px';
      },
      isLoggedIn() {
        let login = this.$store.getters.get_login
        if (!login || !login.id) return false;
        if (login.loginstep) return false;
        return this.$store.getters.get_login?true:false
      },
      getMainSectionTitle(section) {
        //console.log(this.mainMenuItems())
      },
      mainMenuItems() {
  
        var login = this.$store.getters.get_login;
        var allowedPages = [];
        if (login && login.pagePermissions) allowedPages = login.pagePermissions;
        
        return this.$router.options.routes.filter(route=>{
          if (!route.sectionTitle) return false;
          if (!route.icon) return false;
          if (login && login.Role && login.Role.superuser) return true;
          if (!allowedPages.includes(route.name)) return false;
          return (route.sectionTitle && route.path.match(/\//g) || []).length==1
        });
        
      },
      
      
    },
    async beforeMount() {
      console.log('App beforeMount', this.$route)
      
    },
    async mounted() {
        const router = useRouter();

        await router.isReady() 
        console.log('App mounted', this.$route.name, this.$route);
       
        this.loadServices(this.$route.path);
        this.loadStripcards(this.$route.path);
        //this.checkLogin()
    
        this.emitter.on('closeSideBar', e => {
            //console.log("closeSideBar by mitt", e)
            this.sideBar.component=false;
            this.sideBar.data = false;
        })
        this.emitter.on('showAlert', e => {
            console.log("showAlert by mitt", e)
    
            this.showAlert(e.type, e.title, e.message, e.autoclose)
        })
        this.emitter.on('showDialog', e => {
            this.dialog = {};
            this.dialog.title = e.title;
            this.dialog.text = e.text;
            this.dialog.fields = e.fields;
            this.dialog.buttons = e.buttons;

            this.dialogForm = {};
            document.getElementById('dialog').showModal();
        })
        this.emitter.on('hideDialog', e => {
            document.getElementById('dialog').close();
            //dialog.close();
            this.dialog = false;
        })
    
        //this.reloadStores()
        
        }
  }
  </script>
  
  <style>
  .alerts .alert.debug {
    width:120rem;
  }
  
  .sideBarWrap {
    transition: right 0.3s;
  }
  
  
  .marked {
    background-color: yellow;
        background-color: yellow;
      border: 1px solid #310e4e;
      border-radius: 1rem;
      padding: 0.2rem 0.7rem 0.1rem 0.7rem;
  }
  
  .loginPanel.setup2fa img {
    width:200px;
    float:right;
  }
  .loginPanel.setup2fa div {
    float:left;
    width:60%;
    text-align: left;
  }
  .loginPanel.setup2fa div span {
    text-align: left;
    float:left;
    clear: both;
    margin-bottom: 2em;
    font-size: 1.4rem;
  }
  
  .mainWrap {
    right:14rem !important;
  }
  /* .overviewTitleWrap, .tableTitleWrap, .tableEntryWrap, .tableEntryXLWrap {
      min-width: 120rem !important;
  } */

  .loginScreen .loginPanel {

    width: 100rem !important;
  }
  .loginScreen {

    min-height: auto !important;
  }
  .overview {
    text-align: left !important;
  }
  .overviewTitleWrap {
    min-width: auto !important;
  }
  .tableEntry {
    background: var(--bg-color) !important;
}
  .overviewTitleWrap, .tableTitleWrap, .tableEntryWrap, .tableEntryXLWrap {
    min-width: auto !important;
    
}

.loginScreen .table {
  text-align: left;
}
.loginScreen .mainHeader {
  text-align: center;
}
.loginScreen .loginPanel nav {
  text-align: left;
}


.tableEntryXL .tableEntryText .statusLabel {
    margin-right: 1rem !important;
}
  
  </style>
  
  