<template>
<!-- <div class="tableCell" :class="isMarked()?'marked':''" :style="{ width: getColumnWidth() + 'px' }"> -->
<div class="alignCenter">
    <span v-if="!header" class="tableEntryText" :class="isMarked()?'marked':''">
        {{getEntityPropertyValue()}}
    </span>
    <span v-if="header" @click="headerClickFunction" :class="{overviewTitleText:isOverviewTable, tableTitleText:!isOverviewTable}">
        {{getEntityPropertyValue()}}
    </span>
</div>
</template>

<script>
import AbstractTableCell from './AbstractTableCell.vue';
export default {
    extends: AbstractTableCell
}
</script>