<template>
    <!-- <label>{{$props.context.label}}</label> -->

    <Editor
        api-key="ijdjnnnbd5hibj5au4i6iann2ru1ipon26vasmwz4azikuk5"
        :value="localValue"
        v-model="localValue"
        
        model-events="blur"
        :init="getEditorConfig()"
        />

    
</template>

<script>
import Editor from '@tinymce/tinymce-vue'
import { ref } from 'vue';

export default {
    components: {Editor},
    props: {
        context: {
            type:Object,
            required:true
        },

    },
    data() {
        return {
            valueCopy:'',
            
            // initObj: {
            //     height: this.height,
            //     plugins: 'lists link image paste help wordcount',
            //     menubar:false,
            //     toolbar: 'undo redo | blocks | forecolor backcolor | bold italic underline | alignleft aligncenter| link | bullist numlist outdent indent | shortcodes',
            //     toolbar: 'undo redo | blocks | forecolor backcolor | bold italic underline | alignleft aligncenter| link | bullist numlist | shortcodes',
            //     setup: function (editor) {
            //         console.log('setup', this)
            //         editor.ui.registry.addSplitButton('shortcodes', {
            //             text: '{code}',
            //             onAction: function (_) {
            //                 editor.insertContent('<p>Its Friday!</p>')
            //             },
            //             onItemAction: function (buttonApi, value) {
            //                 editor.insertContent(value);
            //             },
            //             fetch: function (callback) {
            //                 // var items = [
            //                 // {
            //                 //     type: 'choiceitem',
            //                 //     text: 'Insert Date',
            //                 //     value: 'sdf'
            //                 // },
            //                 // {
            //                 //     type: 'choiceitem',
            //                 //     text: 'Insert GMT Date',
            //                 //     value: 'dfgdfg'
            //                 // },
            //                 // {
            //                 //     type: 'choiceitem',
            //                 //     text: 'Insert ISO Date',
            //                 //     value: 'fdgdfgdfg'
            //                 // }
            //                 // ];
            //                 // callback(items);
            //                 callback(this.getMailTemplateVariables());
            //             }
            //         });
            //     }
            // }

        }
    },
    methods: {
        getEditorConfig() {
            var _self = this;
            var toolbarOptions = ['blocks', 'forecolor backcolor', 'bold italic underline', 'link', 'bullist numlist'];
            var textFormat = '';
            //console.log('this', this.context.attrs.props)
            //alert(this.context.attrs.props)
            //console.log('this', _self)
            if (this.context.attrs.props && this.context.attrs.props.toolbarOptions) {
                toolbarOptions = this.context.attrs.props.toolbarOptions;
            }
            if (this.context.attrs.props && this.context.attrs.props.textFormat) {
                textFormat = this.context.attrs.props.textFormat;
            }
            if (this.context.attrs.props && this.context.attrs.props.showMailtemplates) {
                toolbarOptions.push('templatesshortcodes');
            }

            var toolbar = toolbarOptions.join(' | ');
            return {
                height: this.height,
                plugins: 'lists link image paste help wordcount table',
                menubar:false,
                content_style: textFormat,
                //toolbar: 'undo redo | blocks | forecolor backcolor | bold italic underline | alignleft aligncenter| link | bullist numlist outdent indent | shortcodes',
                //toolbar: 'undo redo | blocks | forecolor backcolor | bold italic underline | alignleft aligncenter| link | bullist numlist | shortcodes',
                //toolbar: 'blocks | forecolor backcolor | bold italic underline | link | bullist numlist | templatesshortcodes',
                toolbar: toolbarOptions.join(' | '),
                setup: function (editor) {
                   
                    var templates = _self.getMailTemplates()
                    editor.ui.registry.addSplitButton('templatesshortcodes', {
                        text: 'Mailtemplate',
                        //icon: 'link',
                        onAction: function (_) {
                        },
                        onItemAction: function (buttonApi, value) {
                            //editor.insertContent(value);
                            editor.setContent(value.body);
                        },
                        fetch: function (callback) {

                            callback(templates);
                        }
                    });

                }
            }
        },

        handleInput(e) {
            //this.$emit('msgChange', newInputValue);
            //console.log(e)
              this.$props.context.node.input(e.level.content)

        },
        
        async getMailTemplates() {
            //console.log('getMailTemplateVariables')
            var res  = await this.callApi('get', '/mailtemplates');
            return res.data.result.map(item=>{return {
                type: 'choiceitem',
                text: item.name,
                value: item,
            }})
        }
    },
    computed: {
        height() {
                
                if (!this.$props.context.attrs.height) return 400;
                return this.$props.context.attrs.height;
            
        },
        localValue: {
            get () {
                if (this.localValue) {

                    return this.localValue
                }
                return this.$props.context._value;
            },
            set (value) {
                //console.log("set",value)
                //this.localValue = value;
                this.$props.context.node.input(value);
            },

        }
        // propModel: {
        //     get () { return this.$props.context.value },
        //     set (value) { this.$props.context.value.set(value); console.log(value);this.$emit('update:prop', value) },
        // },        
        // val() {
        //     console.log(this.$props)
        //     return this.$props.context.value;
        // }
    },
    watch: {
        // context(newVal) {
        //     this.valueCopy = newVal;
        // }
    }
}
// const props = defineProps({
//     context: Object,
// })
// console.log(props)
// var testval = ref(props.context.value)
// function handleInput(e) {
//     console.log(props)
//     console.log(e)
//     testval = e.level.content
//     //props.context.node.input(e.target.value)
//     //props.context.node.input(e.level.content)
//     //props.context.node.input('234')
// }

</script>
