<template>
    <div v-if="!item._hidden" class="tableEntry">
    
        <div class="tableEntryXL" v-bind:style="{'grid-template-columns': gridColumnWidths}">
            <template v-for="column in columns">
                <component :is="column.component" :item="item" :column="column" />
            </template>
        </div>
        
                    
        <!-- <StripTable v-if="!item._hidden && item._collapsed"
            :items="getActivities"
            :columns="stripcardColumns"
            :tableRow="getTableRowComponent()"
        ></StripTable> -->
        
    
    </div>
</template>
    
<script>


import { shallowRef} from 'vue'

import AppTableRow from '../../../components/base/Table/TableRow';
import StripTable from '../../../components/custom/Stripcard/Table';

import AppTableCell from '../../../components/base/Table/TableCell';

import AppTableCellDate from '../../../components/base/Table/TableCellDate';
import DetailsSideBar from '../../../components/base/Sidebar/Details';

//import AbstractTableRow from './TableRow.vue';

export default {
    extends:AppTableRow,
    emits:['showNotes','showSidebar','showActionBar'],
    //components:{StripTable},
    data() {
        return {
            gridColumnWidths: '1fr 1fr 1fr',
            stripcardColumns:[
                {width:40, name:'id', title:'ID', component:shallowRef(AppTableCell)},
                {width:'4fr', name:'title', title:'Omschrijving', component:shallowRef(AppTableCell)},
                {width:'2fr', name:'create', title:'Datum', component:shallowRef(AppTableCellDate)},   
            ]
        }
    },
        computed: {
            getActivities() {
                return this.$props.item.Stripcardactivies
            },
        },
        methods: {
            getTableRowComponent() {
                //return null
                return AppTableRow;
                //return EnrollmentTableRow;
            },
        },
        props: {
            gridColumnWidths: {
                type: String,
                required:true
            },
            item: {
                type: Object,
                required: true
            },
            columns: {
                type: Array,
                required: true
            }        
        },
        mounted() {
    
        }
    }
    </script>